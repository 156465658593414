<template>
  <div style="width: 1200px;margin: 0 auto;padding-top: 73px;padding-bottom: 61px;">
    <!--标题-->
    <h1 class="fs32 col000 one_overflow">标题：{{info.title}}</h1>
    <div class="difb alc" style="margin-top: 57px;">
      <p class="fs18" style="color: #969696">企业名称：{{info.user_info.nickname}}</p>
      <p class="fs18" style="color: #969696">{{info.createtime_str}}</p>
    </div>
    <div class="border"></div>
    <!--内容-->
    <div class="fs18 col000 break " style="line-height: 1.4em;margin-bottom: 5px;">
      联系人：{{info.linkman}}
    </div>
    <div class="fs18 col000 break " style="line-height: 1.4em;margin-bottom: 5px;">
      联系方式：{{info.contact_way}}
<!--      <span class="fs16  col666 break mrLft20" style="line-height: 1.4em;">
        联系我时请说是在慧邦中工平台看到的
      </span>-->
    </div>

    <div class="fs18 col000 break " style="line-height: 1.4em;margin-bottom: 5px;">
      电子邮件：{{info.mail}}
<!--      <span class="fs16  col666 break mrLft20" style="line-height: 1.4em;">
         报价请传邮箱,报价单总价请写在邮箱标题上面方便观看
      </span>-->
    </div>
    <div class="fs18 dif col000 break" style="line-height: 1.4em;white-space: pre-wrap">
      <p class="fs">信息描述：</p>
      <p v-html="info.describe"></p>
    </div>
    <div class="border2"></div>
    <!--下载报价单-->
    <div class="difcac mrBtm30 mrTop30" v-if="info.quotation_file && info.quotation_name">
      <div class="button difcac" @click="dowload()">
        <i class="el-icon-download colfff fs24"></i>
        <span style="margin-left: 13px;">点击下载{{info.quotation_name}}</span>
      </div>
    </div>
    <!--联系方式-->
    <div class="contact posr" v-if="false">
      <!--背景图-->
      <img src="@/assets/images/icon_bjbj.png" class="w100 h100">
      <!--内容-->
      <div class="contact_warp">
        <h2 class="fs20 box" style="padding-left: 80px;">报价请传邮箱,报价单总价请写在邮箱标题上面方便观看</h2>
        <div class="contact_item difb alc" style="margin-top: 33px;">
          <div>联系人：</div>
          <div style="color: #969696">{{info.linkman}}</div>
        </div>
        <div class="contact_item difb alc">
          <div>联系方式：</div>
          <div style="color: #969696" class="dif alc">
            {{info.contact_way}}
            <img src="@/assets/images/icon_dianhua.png" style="width: 24px;height: 24px;margin-left: 11px;">
          </div>
        </div>
        <div class="contact_item dife alc" style="color: #969696">
            联系我时请说是在慧邦中工平台看到的
        </div>
        <div class="contact_item difb alc">
          <div style="color: #414141">电子邮件：</div>
          <div style="color: #969696">{{info.mail}}</div>
        </div>
      </div>
    </div>


    <!--供应商查看报价记录-->
    <div v-if="userInfo && userInfo.firm_type == 1">
      <h3 class="mb10 tct">我的报价</h3>
      <el-table border :data="glist" style="width: 100%;">
        <el-table-column prop="file_name" label="名称" align="center"></el-table-column>
        <el-table-column prop="total_amount" label="报价金额" align="center"></el-table-column>
        <el-table-column label="报价单" align="center">
          <template slot-scope="scope">
            <!--<a :href="scope.row.offer_file" target="_blank">点击下载</a>-->
            <el-button @click="dowloadUrl(scope.row)" type="primary">点击下载</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="提交时间" align="center"></el-table-column>
      </el-table>
    </div>
    <!--采购商查看报价-->
    <div v-if="userInfo && userInfo.firm_type == 2">
      <h3 class="mb10 tct">供应商报价记录</h3>
      <div class="ml20  box aaa">
        <el-table border :data="glist" style="width: 100%;">
          <el-table-column label="供应商名称" align="center">
            <template slot-scope="scope">
              <div class="posr">
                <el-badge :value="1" :hidden="scope.row.is_click==1">
                  <span>{{scope.row.mch_name}}</span>
                </el-badge>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="total_amount" label="报价金额" align="center"></el-table-column>
          <el-table-column prop="file_name" label="标题" align="center"></el-table-column>
          <el-table-column label="报价单" align="center">
            <template slot-scope="scope">
              <el-button @click="dowloadUrl(scope.row)" type="primary">点击下载</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="createtime" label="提交时间" align="center"></el-table-column>
        </el-table>
      </div>
    </div>


    <!--操作按钮-->
    <div class="difb alc" style="margin-top: 124px;">
      <!--未收藏-->
      <div class="dif alc pointer" v-if="info.is_collect==0" @click="collect()">
        <img src="@/assets/images/icon_shoucang.png" style="width: 24px;height: 24px;margin-right: 12px;">
        <span style="color: #969696;" class="fs18  hover">收藏</span>
      </div>
      <!--已收藏-->
      <div class="dif alc pointer" v-if="info.is_collect==1" @click="collect()">
        <img src="@/assets/images/shoucang2.png" style="width: 24px;height: 24px;margin-right: 12px;">
        <span style="color: #969696;" class="fs18 pointer hover">取消收藏</span>
      </div>
      <!--我要发布-->
      <div class="button difcac" @click="fabu()" v-if="userInfo && userInfo.firm_type == 2">
        <img src="@/assets/images/icon_toubiao.png" style="width: 26px;height: 26px;margin-right: 13px;">
        <span>我要发布</span>
      </div>
      <!--提交报价-->
      <div class="button difcac" @click="showBaojia=true" v-if="userInfo && userInfo.firm_type == 1">
        <img src="@/assets/images/icon_toubiao.png" style="width: 26px;height: 26px;margin-right: 13px;">
        <span>提交报价</span>
      </div>
    </div>


    <!--报价弹窗-->
    <el-dialog title="提交报价" :visible.sync="showBaojia" width="600px" center>
      <el-form ref="form" label-width="130px" label-position="right">
        <el-form-item label="报价单名称" required>
          <el-input v-model="postObj.file_name" placeholder="请输入报价单名称"></el-input>
        </el-form-item>
        <el-form-item label="报价金额" required>
          <el-input v-model="postObj.total_amount" type="number" placeholder="请输入报价金额（元）"></el-input>
        </el-form-item>
        <el-form-item label="报价单" required>
          <el-upload
              :action="$store.state.upLoadUrl"
              :file-list="fileList"
              :on-success="(res,file,fileList)=>onSuccess(res,file)">
            <div  class="dif alc">
              <el-button size="small" type="primary">立即上传</el-button>
              <span class="ml10">可上传Excel、word、WPS等格式</span>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showBaojia=false">取 消</el-button>
        <el-button type="primary" @click="submitBaojia()">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "buyDetail",
  computed: {
    ...mapState(['userInfo'])
  },
  data() {
    return {
      leftCurrent: 0,
      id: '',
      showBaojia: false,//显示提交报价
      postObj: {
        post_id: '',
        file_name: '',
        offer_file: '',
        total_amount: '',
      },
      fileList: [],
      glist: [],//供应商报价列表
      gysList: [],//供应商列表
      info: {
        id: '',
        title: '',//标题
        describe: '',//描述
        linkman: '',//联系人\
        contact_way: '',//联系方式
        mail: '',//邮箱
        quotation_name: '',//报价单名称
        quotation_file: '',//下载路径
        user_info: {
          nickname: '',
        },
        is_collect: 0,//是否收场
        createtime_str: '',
      },
    }
  },
  methods: {
    submitBaojia() { //提交报价
      let data = {...this.postObj}
      data.post_id = this.id
      this.$axios('POST','/post/ask_buy/quotation',data)
      .then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
          this.showBaojia = false
          this.getDetail()
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    onSuccess(res,file,fileList,type) {
      if(res.code==0) {
        this.$message.error(res.msg)
        return
      }
      this.fileList = [file]
      this.postObj.offer_file = res.data.url
    },
    //我要发布
    fabu() {
      eventBus.$emit('showFabu',{});
    },
    //下载
    dowload() {
      window.open(this.info.quotation_file)
    },
    dowloadUrl(item) {
      this.$axios("POST","/post/ask_buy/getOfferFile",{id: item.id})
      .then(res => {
        if(res.code===1) {
          window.open(res.data)
          if(this.userInfo.firm_type==2) {
            this.selectLeftCate()
          }
          this.$store.dispatch('getBadge')
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //收藏
    collect() {
      let data = {
        post_id: this.id,
        type: 1,
        collect_type: this.info.is_collect==1?2:1
      }
      this.$axios("POST","/common/postCollect",data)
      .then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
          this.getDetail()
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //供应商报价列表
    getGlist() {
      this.$axios("POST","/post/ask_buy/getOfferListByMch",{post_id: this.id})
          .then(res => {
            if(res.code===1) {
              this.glist = res.data
            }
          })
    },
    //获取供应商列表
    getGysList() {
      this.$axios('POST','/post/ask_buy/getOfferGongList',{post_id: this.id})
          .then(res => {
            this.gysList = res.data
            if(this.gysList.length>0) this.selectLeftCate(this.gysList[0],0)
          })
    },
    //供应商报价记录
    selectLeftCate(item,index) {
      // this.leftCurrent = index
      this.$axios('POST',"/post/ask_buy/getOfferList",{
        post_id: this.id,
        // mch_id: item.id
      }).then(res => {
        this.glist = res.data || []
      })
    },
    //获取详情
    getDetail() {
      this.$axios("POST","/post/ask_buy/getInfo",{
        id: this.id
      },'加载中').then(res => {
        if(res.code===1) {
          this.info = res.data
          if(this.userInfo.firm_type==1) {
            this.getGlist()
          }else {
            this.selectLeftCate()
          }
        }else {
          this.$alert(res.msg,{type: 'error'})
          .then(eee => {
            window.close()
          })
        }
      })
    },
  },
  created() {
    this.id = this.$route.query.id
    this.getDetail() //获取详情
  }
}
</script>

<style>
  .aaa .el-table .cell{
    overflow: inherit !important;
  }
</style>
<style scoped>
  .border{
    width: 979px;
    height: 1px;
    background: #EEEEEE;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 30px;
  }
  .border2{
    width: 1200px;
    height: 8px;
    margin: 14px 0;
    background: #EEEEEE;
  }
  .contact{
    width: 1200px;
    height: 648px;
    background: #000000;
  }
  .contact_warp{
    width: 906px;
    height: 443px;
    background: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-sizing: border-box;
    padding-top: 68px;
  }
  .contact_item{
    border-bottom: 1px dashed #EEEEEE;
    padding: 19px 148px 17px 59px;
    color: #000000;
    font-size: 18px;
    width: 865px;
    margin: 0 auto;
  }
  .button{
    min-width: 272px;
    height: 55px;
    background: #1572F9;
    padding: 0 20px;
    font-size: 18px;
    color: #ffffff;
    transition: all 0.2s;
    cursor: pointer;
  }
  .button:hover{
    background: #015bd5;
  }

  .left_cate{
    box-sizing: border-box;
    padding: 18px 0;
    width: 150px;
    max-height: 600px;
    /*height: 888px;*/
    /*height: 620px;*/
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: auto;
  }
  .left_cate::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .left_item{
    width: 100%;
    min-height: 34px;
    box-sizing: border-box;
    padding: 8px 4px;
    font-size: 14px;
    color: #000000;
    transition: all 0.3s;
    cursor: pointer;
  }
  .left_item:hover{
    font-weight: bold;
  }
  .left_item.active{
    color: #ffffff;
    background-color: #0B8EFE;
  }
</style>
