<template>
  <div>
    <div class="dif alc mt10 mb10">
      <div class="w-400  mr20">
        <el-input clearable @clear="clickSearch" placeholder="请输入搜索内容" @keyup.enter.native="clickSearch" v-model="keyword" ></el-input>
      </div>
      <el-button type="primary" icon="el-icon-search" @click="clickSearch()">搜索</el-button>
    </div>
    <el-table :data="list"  border  style="width: 100%" >
<!--      <el-table-column align="center" label="头像" width="80">
        <template slot-scope="scope">
          <div class="difcac pointer" @click="showImg(scope.row.mch)"><img class="w-50 h-50" :src="scope.row.mch.logo_image" alt=""></div>
        </template>
      </el-table-column>-->
      <el-table-column align="center" label="采购商名称" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.mch.name}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="采购商地址" >
        <template slot-scope="scope">
          <span>{{scope.row.mch.address}}{{scope.row.mch.info_address}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" align="center" label="申请时间" width="110"></el-table-column>
      <el-table-column  align="center" label="驳回原因" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.status==4">{{scope.row.reason1}}</span>
          <span v-else-if="scope.row.status==5">{{scope.row.reason2}}</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <el-button size="mini" v-if="scope.row.status==1" type="warning"><span class="fs14">待采购商审核</span></el-button>
          <el-button size="mini" v-if="scope.row.status==2" type="warning"><span class="fs14">待后台审核</span></el-button>
          <el-button size="mini" v-if="scope.row.status==3" type="success"><span class="fs14">审核通过</span></el-button>
          <el-button size="mini" v-if="scope.row.status==4" type="danger"><span class="fs14">采购商审核驳回</span></el-button>
          <el-button size="mini" v-if="scope.row.status==5" type="danger"><span class="fs14">平台审核驳回</span></el-button>
          <el-button size="mini" type="primary" @click="showInfo(scope.row)"><span class="fs14">申请信息</span></el-button>
          <div class="mt5" v-if="(scope.row.status==4 || scope.row.status==5) && scope.row.is_bind===0">
            <el-button size="mini"  @click="apply(scope.row)"><span class="fs14">重新提交</span></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <apply-bind @applySuccess="getList" ref="applyBind"></apply-bind>
    <apply-info ref="applyInfo"></apply-info>
  </div>
</template>

<script>
import ApplyBind from "@/views/meCenter/right/components/applyBind.vue";
import ApplyInfo from "@/views/meCenter/right/components/applyInfo.vue";

export default {
  name: "supplierBindList",
  components: {ApplyInfo, ApplyBind},
  data() {
    return {
      list: [],
      keyword: '',
    }
  },
  methods: {
    clickSearch() {
      this.getList()
    },
    showInfo(item) { //查看申请信息
      this.$refs.applyInfo.show(item)
    },
    apply(item) { //申请绑定
      let data=  JSON.parse(JSON.stringify(item))
      data.id = item.mch.id
      this.$refs.applyBind.show(data)
    },
    showImg(item) {
      this.$viewerApi({
        images: [item.logo_image]
      })
    },
    getList() {
      this.$axios("POST","/bind/getApplyList",{keyword: this.keyword,})
          .then(res => {
            this.list = res.data.list
            this.total = res.data.total
            this.$store.dispatch('getBadge')
          })
    }
  },
  mounted() {
    this.getList()
  }
}

</script>

<style scoped>

</style>