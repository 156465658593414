<template>
  <div>
    <!--上面登录 注册-->
    <!--中间logo和搜索-->
    <div class="nav_center difcac mrTop10 mrBtm10">
      <div style="width: 1149px;" class="dif alc">
        <!--logo-->
        <div class="logo">
          <img src="@/assets/logo.png" style="width: auto;height: 92px" alt="">
        </div>
        <!--搜索框-->
        <div>
          <div class="dif alc">
            <!--地址选择-->
<!--            <div class="e_select posr ml20">
              <div class="posr" style="z-index: 333">
                <div class="dif fs alc pointer" @click="openAddress()">
                  <div class="col333" >{{$store.state.address}}</div>
                  <i class="el-icon-arrow-down col333 fs14 ml3"></i>
                </div>
              </div>
              <el-cascader style="position:absolute;bottom: 0;left: 0;z-index: 22"
                           ref="address"
                           size="large"
                           @change="changeCitys"
                           :options="region"
                           v-model="address">
              </el-cascader>
            </div>-->
            <div style="width: 60px;"></div>
            <!--搜索-->
            <div style="margin-left: 36px;">
              <div class="difb alc">
                <div class="search_warp difb alc" >
                  <div class="dif alc h-32 bgff">
                    <!--<div class="difcac pointer e_select w-90 posr">
                      <div class="col333 fs14" @click="openSelect()">{{currentName}}</div>
                      <i class="el-icon-arrow-down col333 fs14"></i>
                      <el-select v-model="currentName" ref="select" placeholder="请选择" style="position:absolute;bottom: 0;left: 0;z-index: 22">
                        <el-option
                            v-for="item in searchList"
                            :label="item.name"
                            :value="item.name">
                        </el-option>
                      </el-select>
                    </div>-->
                    <div class="difcac pointer e_select w-90 posr">
                      <div class="col333 fs14">{{getSearchName}}</div>
                    </div>
                    <input type="text" :placeholder="`请输入要查询的${getSearchName}`" class="w-540 h100" v-model="navList[curentSearch].keyword" @keyup.enter="search()">
                  </div>
                  <div class="search_button difcac fs" @click="search()" >搜 索</div>
                </div>
              </div>
            </div>
          </div>
          <!--导航1-->
          <div class="difb alc mt10">
            <div class="fz14 col333 fs pl28">当天总浏览人数：{{viewNum}}</div>
            <div style="width: 585px;" class="difb fs alc">
              <div class="pointer hover fs16 col000 center_item posr" :class="{'top_active': topCurrentPath===item.path}"
                   v-for="(item,index) in tabs" @click="goPath(item)" :key="index">
                <el-badge :value="$store.state.total_num" v-if="item.id===1" :hidden="item.id!==1 || $store.state.total_num<=0">
                  {{item.name}}
                </el-badge>
                <el-badge :value="$store.state.num2" v-else-if="item.id===3" :hidden="item.id!==3 || $store.state.num2<=0">
                  {{item.name}}
                </el-badge>
                <span v-else>{{item.name}}</span>
              </div>
            </div>
          </div>
        </div>
        <!--发布 登录-->
        <div >
          <div class="login_button difcac" v-if="!userInfo.nickname" @click="goLogin(0)">登录/注册</div>
          <div class="dife alc" v-else>
            <el-popover
                placement="top"
                width="160"
                trigger="hover">
              <div class="difcac">
                  <el-button type="danger" size="mini" @click="logout()">退出登录</el-button>
              </div>
              <div class="login_text mrLft20 one_overflow tct hover " slot="reference" @click="goPath({id: 1,name: '个人中心',path: '/me/myInfo'})">
                {{userInfo.nickname}}
              </div>
            </el-popover>
            <!--<div class="fs16 col333 mrLft10 hover pointer" @click="logout()">退出登录</div>-->
          </div>
          <div class="login_button difcac mrTop10" @click="goPath({id: 6})" v-if="userInfo.audit_status==2">我要发布</div>
          <div class="ml83" style="width: 90px;" v-else></div>
          <!--<div class="pointer hover fs16 col000 difcac center_item button_center" @click="goPath({id: 6})">我要发布</div>-->
        </div>
      </div>
    </div>
    <!--底部导航栏-->
    <div class="nav_bottom">
      <div class="difcac mr0At h100 nav_bottom_cont">
        <div class="nav_item difcac h100 " :class="{'active': currentPath==item.path}" @click="goPath(item)"
             v-for="(item,index) in navList" :key="index">{{getTabName(item)}}</div>
      </div>
    </div>

    <transition enter-active-class="animation-scale-up" leave-active-class="animation-scale-up reverse">
      <add-list v-if="showAddList" @hideWarp="hide" ></add-list>
    </transition>
  </div>
</template>

<script>
import AddList from "@/components/common/addList";
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'

import {
  mapState,
  mapMutations
}from 'vuex';
export default {
  name: "navbar",
  components: {AddList},
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    getSearchName() {
      if(this.userInfo.firm_type==2 && this.navList[this.curentSearch].id===13) {
        return  '供货商'
      }
      return this.navList[this.curentSearch].searchName

    },

  },
  data() {
    return {
      viewNum:0,//浏览数
      region: regionData,
      address: '获取中',
      code: '',//省市区code
      keyword: '',//搜索关键词
      tabs: [ //搜索下方的tab
        {id: 1,name: '个人中心',path: '/me/myInfo'},
        // {id: 2,name: '我的订单',path: '/me/myOrder'},
        // {id: 3,name: '我的收藏',path: '/myCollect'},
        {id: 3,name: '报价表',path: '/bidding'},
        // {id: 4,name: '购物车',path: '/cart'},
        {id: 5,name: '积分兑换',path: '/integralMall'},
        {id: 5,name: '我的提成',path: '/me/myCommission'},
      ],
      currentName: '店铺',
      searchList: [
        {searchName:'店铺',keyword: '',placeholder: ''},
        {searchName:'采购信息',keyword: '',placeholder: ''},
        {searchName:'安装工程',keyword: '',placeholder: ''},
        {searchName:'物流货运',keyword: '',placeholder: ''},
        {searchName:'商城',keyword: '',placeholder: ''},
        {searchName:'二手设备',keyword: '',placeholder: ''},
      ],
      curentSearch: 0,
      navList: [ //导航栏
        {id: 8,name: '采购信息',path: '/',searchName:'采购信息',keyword: '',placeholder: ''},
        {id: 7,name: '优质商户',path: '/shops',searchName:'商户',keyword: '',placeholder: ''},
        // {id: 9,name: '安装工程信息',path: '/insworks',searchName:'安装工程',keyword: '',placeholder: ''},
        // {id: 10,name: '物流货运信息',path: '/logistics',searchName:'物流货运',keyword: '',placeholder: ''},
        // {id: 11,name: '人才招聘',path: '/recruit'},
        // {id: 12,name: '求职信息',path: '/jobwanted'},
        {id: 13,name: '供货商',path: '/industry',searchName:'商品',keyword: '',placeholder: ''},
        // {id: 14,name: '日用品商城',path: '/daily'},
        {id: 15,name: '二手设备',path: '/secondhand',searchName:'二手设备',keyword: '',placeholder: ''},
      ],
      currentPath: '/',//当前选中的路由
      topCurrentPath: '/',
      showAddList: false,//是否显示发我要发布列表
    }
  },
  methods: {
    getTabName(item) {
      if(this.userInfo.firm_type==2 && item.id===13) {
        return  '我的供应商'
      }
      if(this.userInfo.firm_type==1 && item.id===13) {
        return  '我的产品'
      }
      return item.name
    },
    getviewNum() {
      this.$axios('POST','/index/getViewNum',{})
          .then(res => {
            this.viewNum = res.data || 0
          })
    },
    // 选择城市
    changeCitys(e) {
     /* if(CodeToText[e[1]]==='市辖区') {
        this.address = CodeToText[e[0]]
        console.log(this.address);
        this.$store.commit('setAddress',{code: e[1],address: CodeToText[e[0]]})
      }else {
        this.address = CodeToText[e[1]]
        this.$store.commit('setAddress',{code: e[1],address: CodeToText[e[1]]})
      }*/
      this.address = CodeToText[e[2]]
      this.$store.commit('setAddress',{code: e[1],address: CodeToText[e[2]]})
      this.$router.go(0)
    },
    openAddress() {
      this.$refs.address.toggleDropDownVisible(true)
    },
    openSelect() {
      this.$refs.select.toggleMenu()
    },
    logout() { //退出登录
      this.$confirm('确认要退出该账户吗？',{type: 'info'})
      .then(con => {
        localStorage.removeItem('hbzg')
        localStorage.removeItem('hbInfo')
        this.$store.commit('logout')
        this.$router.push({
          path: '/login',
          query: {type: 0}
        })
      })
    },
    // 隐藏弹窗
    hide(e) {
      console.log(e);
      if(e.type===1) {
        this.showAddList = false
      }
    },
    // tab或nav点击
    goPath(item) {
      let token = localStorage.getItem('hbzg')
      if(item.id>=1 && item.id<=6 && !token) {
        return this.$alert('请登录后操作',{type: 'error'})
        .then(res=>{
          this.$router.push({
            path: '/login',query:{type: 0}
          })
        })
      }
      if(item.id===6) { //我要发布
        return this.showAddList = true
      }
      if(item.id===4) { //跳转到购物车  再点击刷新
        console.log(this.$route.path);
        if(this.$route.path==='/cart') {
          this.$router.go(0)
        }else {
          this.$router.push({
            path: item.path
          })
        }
        return
      }
      this.$router.push({
        path: item.path
      })
    },
    //跳转到登录页面
    goLogin(type) { //0 登录 1注册
      this.$router.push({
        path: '/login',
        query: {type: type}
      })
    },
    //显示发布
    showFabu(){
      this.showAddList = true
    },
    //搜索
    search() {
      let keyword = this.navList[this.curentSearch].keyword
      // if(keyword==='') {
      //   return this.$message.error('请输入搜索内容')
      // }
      let currentPath = this.$route.matched[0].path
      let path = '/search'
      console.log(currentPath)
      if(currentPath==='/') {
        path = '/search'
      }else if(currentPath==='/shops'){
         path = '/shops'
      }else if(currentPath==='/insworks'){
         path = '/insworks/search'
      }else if(currentPath==='/logistics') {
        path = '/logistics/search'
      }else if(currentPath==='/secondhand') {
        path = '/secondhand/search'
      }else if(currentPath==='/industry'){
        path = '/industry/search'
      }else {
        path = '/'
      }
      this.$router.push({
        path: path,
        query: {
          s: keyword,
          time: new Date().getTime()
        }
      })
    },
    //获取定位
    getLocation() {
      let address = localStorage.getItem('hb_address')
      let code = localStorage.getItem('hb_code')
      /*if(address && code) {
        this.$store.commit('setAddress',{
          address: address,
          code: code
        })
        return
      }*/
      var  geolocation = new AMap.Geolocation({
        enableHighAccuracy: true,//是否使用高精度定位，默认:true
        timeout: 100000,
        maximumAge: 0,           //定位结果缓存0毫秒，默认：0
        needAddress: true,
        extensions: 'all'
      });
      let that = this;
      geolocation.getCityInfo(function (status,res) {
        // 逆地理编码
        let geocoder = new AMap.Geocoder({
          extensions: 'all'
        })
        let lnglat = res.position
        geocoder.getAddress(lnglat,function (code,res1) {
          if (code === 'complete' && res1.info === 'OK') {
            let data = res1.regeocode.addressComponent
            that.code = data.adcode.substring(0, 4)+'00' //市级编码
            if(data.district) {
              that.address = data.district
              that.$store.commit('setAddress',{address: data.district,code: that.code})
            }else if(data.city) { //区名
              that.address = data.city
              that.$store.commit('setAddress',{address: data.city,code: that.code})
            }else if(data.province){ //直辖市
              that.address = data.province
              that.$store.commit('setAddress',{address: data.province,code: that.code})
            }
          }else {
            that.address = '济南市'
            that.code = '370100'
            that.$store.commit('setAddress',{address: that.address,code: that.code})
          }
        })
      });
    },
  },
  watch: {
    $route(newValue) {
      // console.log(newValue);
      if(newValue.matched.length>0) {
        this.currentPath = newValue.matched[0].path || '/'
        this.topCurrentPath = newValue.path
        let index = this.navList.findIndex(item => item.path===this.currentPath)
        if(index>=0) {
          this.curentSearch = index
        }else {
          this.curentSearch = 0
        }
        this.getviewNum()
      }
    }
  },
  created() {
    this.getviewNum()
    this.getLocation();
    eventBus.$on('showFabu',this.showFabu)
    if(this.$route.matched.length>0) {
      this.currentPath = this.$route.matched[0].path || '/'
      let index = this.navList.findIndex(item => item.path===this.currentPath)
      if(index>=0) {
        this.curentSearch = index
      }else {
        this.curentSearch = 0
      }
      console.log(this.currentPath);
      this.topCurrentPath = this.$route.path
    }
  }
}
</script>

<style scoped>
.e_select ::v-deep.el-input__suffix{
  display: none;
}
.center_item ::v-deep.el-badge__content{
  line-height: 16px !important;
}

.e_select ::v-deep.el-input__inner{
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 0 17px;
  font-size: 16px;
  opacity: 0;
}
 /* .top_active{
    color: #0B8EFE;
  }
  .nav_top{
    width: 100%;
    height: 34px;
    background: rgb(242,242,242);
    font-size: 14px;
    color: #414141;
  }
  .nav_center{
    width: 100%;
    height: 105px;
    color: #ffffff;
    background-color: #ffffff;
  }
  .nav_bottom{
    background-color: #1572F9;
    height: 50px;
    width: 100%;
  }
  .logo{
    color: #1572F9;
    font-size: 49px;
  }
  .search_warp{
    width: 583px;
    height: 46px;
    background: #1572F9;
    box-sizing: border-box;
    padding-left: 5px;
  }
  .search_warp input{
    width: 458px;
    height: 38px;
    background: #FFFFFF;
    font-size: 16px;
    box-sizing: border-box;
    padding: 0 8px;
  }
  .search_button{
    margin-right: 4px;
    box-sizing: border-box;
    height: 100%;
    padding: 0 35px;
    cursor: pointer;
  }
  .nav_item{
    width: 133px;
    font-size: 20px;
    color: #ffffff;
    transition: all 0.3s;
    cursor: pointer;
  }
  .nav_item:hover{
    font-size: 21px;
  }
  .nav_item.active{
    font-weight: bold;
    background-color: #0B8EFE;
  }*/
  .button_center{
    color: #ffffff;
    background-color: #1572F9;
    cursor: pointer;
    height:22px;
    border-radius: 15px;
    width: 80px
  }
 .top_active{
   color: #0B8EFE;
 }
 .nav_top{
   width: 100%;
   height: 34px;
   background: rgb(242,242,242);
   font-size: 14px;
   color: #414141;
 }
 .nav_center{
   padding: 5px 0;
   width: 100%;
   color: #ffffff;
   background-color: #ffffff;
 }
 .nav_bottom{
   /*background-color: #1572F9;*/
   height: 40px;
   width: 100%;
 }
 .nav_bottom_cont{
   background-color: #1572F9;
   width: 1200px;
 }
 .logo{
   color: #1572F9;
   font-size: 39px;
 }
 .search_warp{
   width: 752px;
   height: 40px;
   background: #1572F9;
   box-sizing: border-box;
   padding-left: 5px;
 }
 .search_warp input{
   background: #FFFFFF;
   font-size: 14px;
   box-sizing: border-box;
   padding: 0 8px;
 }
 .search_button{
   margin-right: 4px;
   box-sizing: border-box;
   height: 100%;
   padding: 0 35px;
   cursor: pointer;
 }
 .nav_item{
   /*width: 133px;*/
   //width: 200px;
   flex: 1;
   font-size: 18px;
   color: #ffffff;
   transition: all 0.3s;
   cursor: pointer;
 }
 .nav_item:hover{
   font-size: 20px;
 }
 .nav_item.active{
   font-weight: bold;
   background-color: #0B8EFE;
 }
 .center_item{
   font-size: 14px;
 }
 .login_button{
   margin-left: 86px;
   font-size: 14px;
   color: #ffffff;
   background-color: #1572F9;
   cursor: pointer;
   height: 30px;
   border-radius: 15px;
   width: 90px
 }
 .login_text{
   font-size: 14px;
   color: #333333;
   cursor: pointer;
   width: 90px;
   word-break: break-all;
 }
</style>
