<template>
  <div class="difb">
    <!--左侧大图片-->
    <div class="login_left_bg posr">
      <img src="@/assets/images/icon_dlbj.png">
    </div>
    <!--右侧登录内容-->
    <div class="difcc posr" style="width: 35vw;">
      <div class="dif alc homego" @click="goHome()">
        <p>返回首页</p>
        <i class="el-icon-arrow-right" style="color: #666666; font-size: 16px;"></i>
      </div>
      <!--登录框-->
      <transition enter-active-class="animation-scale-up">
        <div class="difcc posa"  v-if="current==0">
          <div style="width: 30.94rem;margin-bottom: 2.19rem;border-bottom: 1px solid #888888;padding-bottom: 0.5rem;" class="difb alc">
            <span style="font-size: 42px;color: #1572F9" class="bold">用户登录</span>
            <span style="font-size: 1.75rem;padding-top: 8px;" class="bold">
            <span style="color: #414141">没有账号？</span>
            <span class="pointer hover1" style="color: #1572F9" @click="current=1">立即注册</span>
          </span>
          </div>
          <!--登录方式选择-->
          <div class="difb alc" style="width: 27.63rem;border-bottom: 1px solid #888888;padding-bottom: 1rem;">
            <div class="login_tab" @click="login_current=0" :class="{'active': login_current==0}">普通登录</div>
            <div style="width: 1px;height: 30px;background-color: #888888"></div>
            <div class="login_tab" @click="login_current=1" :class="{'active': login_current==1}">手机号快速登录</div>
          </div>
          <!--普通登录-->
          <div>
            <div class="input_warp dif alc">
              <div style="width: 20%;" class="fs18 col333">手机号：</div>
              <div style="width: 80%;">
                <el-input v-model="login_obj.account" maxlength="11" placeholder="请输入手机号"></el-input>
              </div>
            </div>
            <div class="input_warp dif alc" v-show="login_current==0">
              <div style="width: 20%;" class="fs18 col333">密码：</div>
              <div style="width: 80%;">
                <el-input v-model="login_obj.password" placeholder="请输入密码" type="password"></el-input>
              </div>
            </div>
            <div class="input_warp posr dif alc" v-show="login_current==1">
              <div style="width: 20%;" class="fs18 col333">验证码：</div>
              <div class="posr" style="width: 80%;">
                <span class="getCode hover">
                <span v-if="time===31" @click="getCode(1)">获取验证码</span>
                <span v-else>{{time}}秒后重试</span>
              </span>
                <el-input v-model="login_obj.captcha" maxlength="11" placeholder="请输入验证码"></el-input>
              </div>
            </div>
            <div class="input_warp trt fz18 col000" style="margin-top: 19px;margin-bottom: 35px;">
              <span class="pointer hover" style="color: #1572F9" @click="current=3">忘记密码？</span>
            </div>
            <div class="login_button difcac" @click="login()">登录</div>
          </div>
        </div>
      </transition>
      <!--注册框-->
      <transition enter-active-class="animation-scale-up">
        <div class="difcc posa mb40" v-if="current==1">
          <div style="width: 30.94rem;padding-bottom: 0.5rem;border-bottom: 1px solid #888888;" class="dif alc">
            <span style="font-size: 26px;color: #1572F9" class="bold">立即注册</span>
            <span style="font-size: 16px;padding-top: 8px;" class="bold">
            <span style="color: #414141" class="ml40">有账号？</span>
            <span class="pointer hover" style="color: #1572F9" @click="current=0">去登录</span>
          </span>
          </div>
          <!--注册-->
          <div>
            <div style="max-height: 70vh;overflow-y: auto;">
              <div class="input_warp2 dif alc">
                <div style="width: 28%;" class="fs18 col333">
                  <span class="text-red">*</span>
                  企业名称：
                </div>
                <div style="width: 72%;">
                  <el-input v-model="register_obj.name" placeholder="请输入企业名称"></el-input>
                </div>
              </div>
              <div class="input_warp2 dif alc">
                <div style="width: 28%;" class="fs18 col333">
                  <span class="text-red">*</span>
                  企业电话：
                </div>
                <div style="width: 72%;">
                  <el-input v-model="register_obj.mobile" placeholder="请输入企业电话"></el-input>
                </div>
              </div>
              <div class="input_warp2 dif alc">
                <div style="width: 28%;" class="fs18 col333">
                  <span class="text-red">*</span>
                  联系人：
                </div>
                <div style="width: 72%;">
                  <el-input v-model="register_obj.link_name" placeholder="请输入联系人"></el-input>
                </div>
              </div>
              <div class="input_warp2 dif alc posr">
                <div class="fs18 col333 " style="width: 28%;">
                  <span class="text-red">*</span>
                  验证码：
                </div>
                <div class=" posr" style="width: 72%;">
                <span class="getCode hover" style="font-size: 14px;">
                  <span v-if="time===31" @click="getCode(2)">获取验证码</span>
                  <span v-else>{{time}}秒后重试</span>
                </span>
                  <el-input v-model="register_obj.code" maxlength="11" placeholder="请输入验证码"></el-input>
                </div>
              </div>
              <div class="input_warp2 dif alc">
                <div class="fs18 col333" style="width: 28%;">
                  <span class="text-red">*</span>
                  密码：</div>
                <div style="width: 72%;">
                  <el-input v-model="register_obj.password" placeholder="请输入密码" type="password"></el-input>
                </div>
              </div>
              <div class="input_warp2 dif alc">
                <div class="fs18 col333" style="width: 28%;">
                  <span class="text-red">*</span>
                  确认密码：</div>
                <div style="width: 72%;">
                  <el-input v-model="register_obj.re_password" placeholder="请确认密码" type="password"></el-input>
                </div>
              </div>
              <div class="input_warp2 dif alc">
                <div class="fs18 col333" style="width:28%;">
                  <span class="text-red">*</span>
                  企业分类：</div>
                <el-select v-model="register_obj.firm_type" placeholder="请选择企业分类" style="width: 72%;">
                  <el-option
                      v-for="item in firmTypes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="input_warp2 dif alc">
                <div class="fs18 col333" style="width: 28%;">
                  <span class="text-red">*</span>
                  行业分类：</div>
                <el-select v-model="register_obj.industry_id" style="width: 72%;" placeholder="请选择行业分类">
                  <el-option v-for="item of cates" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </div>
              <div class="input_warp2 dif alc" v-if="register_obj.firm_type===1">
                <div style="width: 28%;" class="fs18 col333">
                  <span style="color: rgba(0,0,0,0)">*</span>
                  邀请码：
                </div>
                <div style="width: 72%;">
                  <el-input v-model="register_obj.p_invite_code"  placeholder="选填"></el-input>
                </div>
              </div>
              <div class="input_warp2 dif alc" >
                <div style="width: 32%;" class="fs18 col333">
                  代理商邀请码：
                </div>
                <div style="width: 72%;">
                  <el-input v-model="register_obj.agent_code"  placeholder="选填"></el-input>
                </div>
              </div>
              <div class="input_warp2 dif alc">
                <div class="fs18 col333" style="width:28%;">
                  <span class="text-red">*</span>
                  入驻区域：</div>
                <el-cascader style="width: 72%;"
                    size="large"
                    :options="citys"
                    v-model="selectedOptions">
                </el-cascader>
              </div>
              <div class="input_warp2 dif alc">
                <div style="width: 28%;" class="fs18 col333">
                  <span class="text-red">*</span>
                  详细地址：
                </div>
                <div style="width: 72%;">
                  <el-input v-model="register_obj.info_address" placeholder="请输入详细地址"></el-input>
                </div>
              </div>
              <div class="input_warp2">
                <div class="fs18 col333" >
                  <span class="text-red">*</span>
                  营业执照以及公司资质：</div>
                <div class="mt10 registger_upload">
                  <el-upload
                      :action="$store.state.upLoadUrl"
                      :headers="{'token': token}"
                      :file-list="fileList"
                      :on-remove="onRemove"
                      :limit="3"
                      :on-success="onSuccess"
                      :on-exceed="onExceed"
                      list-type="picture-card">
                    <i class="el-icon-plus" ></i>
                  </el-upload>
                </div>
              </div>
            </div>


            <div class="did alc mrTop20 mrBtm30 dif alc">
              <img src="@/assets/images/03no_check.png" v-if="!isAgree" class="checked" @click="isAgree=!isAgree">
              <img src="@/assets/images/03yes_check.png" v-else class="checked" @click="isAgree=!isAgree">
              <span class="mrLft10">
                <span>我已阅读并同意</span>
                <span class="pointer hover fz18" style="color: #1572F9" @click="goXieyi()">《用户协议》</span>
              </span>
            </div>
            <div class="login_button difcac" :class="{'gray': !isAgree}" @click="register()">注册</div>
          </div>
        </div >
      </transition>
      <!--找回密码-->
      <transition enter-active-class="animation-scale-up">
        <div class="difcc posa" v-if="current==3">
          <div style="width: 30.94rem;margin-bottom: 1.19rem;border-bottom: 1px solid #888888;padding-bottom: 0.5rem;" class="difb alc">
            <span style="font-size: 42px;color: #1572F9" class="bold">找回密码</span>
            <span style="font-size: 1.75rem;padding-top: 8px;" class="bold">
            <span class="pointer hover" style="color: #1572F9" @click="current=0">去登录</span>
          </span>
          </div>
          <!--找回密码-->
          <div>
            <div class="input_warp dif alc">
              <div class="w20 col333 fs20">手机号：</div>
              <div class="w80">
                <el-input v-model="forget_obj.mobile" placeholder="请输入手机号"></el-input>
              </div>
            </div>
            <div class="input_warp posr dif alc">
              <div class="w20 col333 fs20">验证码：</div>
              <div class="w80 posr">
                <span class="getCode hover">
                <span v-if="time===31" @click="getCode(3)">获取验证码</span>
                <span v-else>{{time}}秒后重试</span>
              </span>
                <el-input v-model="forget_obj.captcha" maxlength="11" placeholder="请输入验证码"></el-input>
              </div>
            </div>
            <div class="input_warp dif alc">
              <div class="w20 col333 fs20">密码：</div>
              <div class="w80">
                <el-input v-model="forget_obj.newpassword" placeholder="请输入新密码" type="password"></el-input>
              </div>
            </div>
            <div class="input_warp dif alc">
              <div class="col333 fs20" style="width: 25%;">确认密码：</div>
              <div style="width: 75%;">
                <el-input v-model="forget_obj.newpassword2" placeholder="请确认密码" type="password"></el-input>
              </div>
            </div>
            <div class="login_button difcac" style="margin-top: 30px;" @click="forget()">找回密码</div>
          </div>
        </div >
      </transition>

      <a href="https://beian.miit.gov.cn" target="_blank" style="color: #333;text-decoration: none" class="beiuan" v-if="config.beian"> {{config.beian}}</a>
    </div>

    <el-dialog :visible.sync="showContent"  center title="用户协议">
      <span>内容</span>
    </el-dialog>
  </div>
</template>

<script>
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
export default {
  name: "Login",
  data() {
    return {
      fileList: [],//营业执照
      citys: regionData,
      current: 1,// 0登录 1注册 2忘记密码
      login_current: 0,//0 普通登录 1手机号快速登录
      login_obj: { //登录
        account: '',
        password: '',
        captcha: '',
      },
      register_obj: { //注册
        username: '',//手机号
        password: '',//密码
        info_address: '',//详细地址
        re_password: '',//确认密码
        code: '',//验证码
        industry_id: '',//行业id
        p_invite_code: '',//邀请码
        agent_code: '',//代理商邀请码
        firm_type: '',//企业分类:1=供应商,2=采购商
        name: '',//企业名称
        mobile: '',//联系电话
        license_images: '',//营业执照
        address: '',//入驻区域
        sheng_code: '',
        shi_code: '',
        qu_code: '',
        link_name: ''
      },
      selectedOptions: [],//选中的地址
      firmTypes: [ //企业分类
        {label: '供应商',value: 1},
        {label: '采购商',value: 2},
      ],
      token: '',
      forget_obj: { //找回密码
        mobile: '',
        captcha: '',
        newpassword: '',//新密码
        newpassword2: '',//重复密码
      },
      isAgree: false,//是否同意协议
      showContent: false,//显示协议内容
      cates: [],//行业分类
      //验证码计时器
      time: 31,
      timeout: null,
      config: {
        name: '',//站点名称
        beian: '',//备案
        address: '',//公司地址
        postcode: '',//邮编
      },
    }
  },
  methods: {
    goXieyi() { //用户协议
      window.open('http://share.huizukeji.cn/index/index/text?id=164','_blank')
    },
    //返回首页
    goHome() {
      this.$router.push('/shops')
    },
    //获取行业分类
    getCates() {
      this.$axios("POST","/index/getIndustry",{})
      .then(res => {
        if(res.code===1) this.cates = res.data
      })
    },
    //获取验证码 1登录 2注册 3忘记密码
    getCode(type) {
      let data = {
        mobile: this.login_obj.account,
        event: 'mobilelogin'
      }
      if(type===2) {
        data.mobile = this.register_obj.mobile
        data.event = 'register'
      }else if(type===3) {
        data.mobile = this.forget_obj.mobile
        data.event = 'changepwd'
      }
      if(data.mobile==='') {
        return this.$alert('请输入手机号',{type: 'error'})
      }
      clearInterval(this.timeout)
      this.$axios("POST","/sms/send",data,'发送中')
      .then(res => {
        if(res.code===1) {
          this.timeout = setInterval(()=>{
            this.time--
            if(this.time<=0) {
              this.time = 31
              clearInterval(this.timeout)
            }
          },1000)
          this.$message.success('发送成功')
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //找回密码
    forget() {
      let data = this.forget_obj
      if(data.mobile==='') {
        return this.$alert('请输入手机号',{type: 'error'})
      }else if(data.captcha==='') {
        return this.$alert('请输入验证码',{type: 'error'})
      }else if(data.newpassword==='') {
        return this.$alert('请输入密码',{type: 'error'})
      }else if(data.newpassword2==='') {
        return this.$alert('请确认密码',{type: 'error'})
      }else if(data.newpassword2!==data.newpassword) {
        return this.$alert('两次输入密码不一致',{type: 'error'})
      }
      this.$axios("POST","/user/changepwd",data,'加载中')
      .then(res => {
        if(res.code===1) {
          this.$alert('修改密码成功',{type: 'success'})
          .then(con => this.current = 0)
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //注册
    register() {
      let data = this.register_obj
      // 地址
      if(this.selectedOptions.length>0) {
        data.sheng_code =  this.selectedOptions[0]
        data.shi_code =  this.selectedOptions[1]
        data.qu_code =  this.selectedOptions[2]
        data.address = `${CodeToText[this.selectedOptions[0]]}-${CodeToText[this.selectedOptions[1]]}-${CodeToText[this.selectedOptions[2]]}`
      }
      // 上传的图片
      let imgs = []
      for(let item of this.fileList) {
        if(item.response.code==1) {
          imgs.push(item.response.data.url)
        }
      }
      data.license_images = imgs.join(',');
      if(!this.isAgree) {
        return this.$alert('请先阅读并同意协议内容',{type: 'error'})
      }
      this.$axios("POST","/user/register",data,'注册中')
      .then(res => {
        if(res.code===1) {
          this.login_obj.account = data.username
          this.$alert('注册成功',{type: 'success'})
          .then(con => {
            localStorage.setItem('hbzg',res.data.token)
            this.$router.replace('/Exstatus')
          })
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //登录
    login() {
      let data = {}
      let url = '/user/login'
      if(data.mobile==='') {
        return this.$alert('请输入手机号',{type: 'error'})
      }
      if(this.login_current===0) { //普通的登录
        data.account = this.login_obj.account
        data.password = this.login_obj.password
        if(data.password==='') {
          return this.$alert('请输入密码',{type: 'error'})
        }
      }else { //验证码登录
        url = '/user/mobilelogin'
        data.captcha = this.login_obj.captcha
        data.mobile = this.login_obj.account
        if(data.captcha==='') {
          return this.$alert('请输入验证码',{type: 'error'})
        }
      }
      this.$axios("POST",url,data,'登录中')
      .then(res => {
        if(res.code===1) {
          this.$message.success('登录成功')
          localStorage.setItem('hbzg',res.data.token)
          this.getUserInfo() //获取用户信息
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //获取用户信息
    getUserInfo() {
      this.$axios("POST","/user/getUserInfo",{})
      .then(res => {
        if(res.code===1) {
          localStorage.setItem('hbInfo',JSON.stringify(res.data))
          this.$store.commit('setUserInfo',res.data)
          if(res.data.audit_status===2) { //审核通过
            this.$router.replace('/')
          }else {
            this.$router.replace('/Exstatus')
          }
        }else {
          localStorage.removeItem('hbzg')
          this.$alert('获取用户信息失败，请重试',{type: 'error'})
        }
      })
    },
    getConfig() { //获取系统配置
      this.$axios("POST","/index/getConfig",{})
          .then(res => {
            if(res.code===1) this.config = res.data
          })
    },

    onSuccess(res, file, fileList) {
      this.fileList = fileList
      console.log(fileList);
    },
    onRemove(file,fileList) {
      this.fileList = fileList
    },
    onExceed() {
      this.$alert('最多上传3张照片',{type: 'info'})
    },
  },
  created() {
    this.getCates() //获取行业分类
    this.getConfig() //获取行业分类
    setTimeout(()=>{
      this.current = this.$route.query.type
      if(this.$route.query.code) { //邀请码
        this.register_obj.p_invite_code = this.$route.query.code
        this.register_obj.firm_type = 1
      }
    },100)
  }
}
</script>

<style scoped>
  .beiuan{
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
  }
  .homego {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 16px;
    color: #666666;
    cursor: pointer;
  }
  .login_left_bg{
    width: 65vw;
    height: 100vh;
    overflow: hidden;
  }
  .login_left_bg img{
    width:  100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .hover1:hover{
    text-decoration: none;
  }
  .login_tab{
    font-size: 1.5rem;
    color: #000000;
    transition: all 0.3s;
    cursor: pointer;
  }
  .login_tab.active{
    color: #1572F9;
    font-weight: bold;
    position: relative;
  }
  .login_tab.active::before{
    content: '';
    background-color: #1572F9;
    display: block;
    position: absolute;
    bottom: -10px;
    width: 50%;
    height: 3px;
    left: 50%;
    transform: translate(-50%);
  }
  .input_warp,.input_warp2{
    width: 25.69rem;
    margin-top: 1.44rem;
  }
  .input_warp2{
    margin-top: 1rem;
  }
  .input_warp ::v-deep.el-input__inner{
    width: 100%;
    height: 3.46rem;
    background: #FFFFFF;
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
    border: 0.13rem solid #DCDFE6;
    font-size: 18px;
  }
  .input_warp2 ::v-deep.el-input__inner{
    width: 100%;
    height: 2.96rem;
    background: #FFFFFF;
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
    border: 0.13rem solid #DCDFE6;
    font-size: 15px;
  }
  .login_button{
    width: 443px;
    height: 65px;
    background: #007AFF;
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
  }
  .login_button.gray{
    background-color: #666666;
  }
  .getCode{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    color: #1572F9;
    font-size: 18px;
    z-index: 22;
    cursor: pointer;
  }
  .checked{
    width: 20px;
    height: 19px;
    cursor: pointer;
  }
  .registger_upload ::v-deep.el-upload--picture-card {
    width: 100px !important;
    height: 100px !important;
    line-height: 100px;
  }
  .registger_upload ::v-deep.el-upload-list__item {
    width: 100px;
    height: 100px;
  }
</style>